//Alex - Modules functionality
//My own jQuery ---- ALEX
jQuery(function($){ 
    "use strict";

	var url = window.location.href;
	/*if(url==="https://kostours.lex.style:1000:7000/index.html")
	{
		window.location.href="/logout";
	}*/
	//Function to get URL Parameters
	var getUrlParameter = function getUrlParameter(sParam) {
		var sPageURL = window.location.search.substring(1),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
			}
		}
	};
	//Error handling function
	function handleErrors (request, status, error) {
		$('.modal').modal('hide');
		var error_srv=request.responseText;
		//alert(error);
		//alert(error_srv);
		swal(
			{
				title: 'Error!',
				text: error_srv,
				type: 'error',
			}
		).then(function (result) {
			if (result.value) {
			// handle confirm
				location.reload();
			}
		})
	}
	//Get Client Information
	$.ajax({
			type: 'post',
			url: 'https://kostours.lex.style/userinfo',
			dataType: 'json',			
			success: function(data) {
				//alert('bien');
				$("#dash-username").html(data.fullname);
				$("#welcome_user").html('Welcome '+data.name+'!');
			},
			error : function(request, status, error) {

				var val = request.responseText;
				//alert(val);
			}
	});
	//Get the Footer Client Name
	$.ajax({
			type: 'post',
			url: 'https://kostours.lex.style/footer',
			dataType: 'html',			
			success: function(data) {
				//alert('bien');
				$(".app_client_name").text(data);
				//$("#alex_res").text(data);
			},
			error : function(request, status, error) {

				var val = request.responseText;
				//alert(val);
			}
	});
	
	if(url.indexOf("/index") > -1)
	{
		//Fill Dashboard pending bookings table
		$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/dash_table',
				dataType: 'json',		
				success: function(data) {
					//alert('bien');
					if(data!=null)
					{
						$("#dash_bkng").empty();
						var content="";
						$.each( data, function( key, value ) {
							var date=moment(value.date).utc().format('MM/DD/YYYY');
							content+="<tr>";
							content+="<td><p class='mb-0 font-weight-bold'><a href='https://kostours.lex.style/client?id="+value.id+"'>"+value.name+"</a></p><span class='font-13'>"+value.email+"</span></td>";
							content+="<td><span class='phone'>"+value.phone+"</span></td>";
							content+="<td><span class='tour_name'>"+value.tour+"</span></td>";
							content+="<td><span class='tour_date'>"+date+"</span></td>";
							content+="<td><span class='guests'>"+value.guests+"</span></td>";
							content+="</tr>";
						});
						$("#dash_bkng").html(content);
					}
				},
				error : function(request, status, error) {

					var val = request.responseText;
					alert("error"+status);
				}
		});
	}
	/*=========================================
	====== LOGIN PAGE =========================
	*/
	//if(url.indexOf("kostours.lex.style:1000") > -1)
	//{
	if(url==="https://kostours.lex.style/")
	{
		var cookie_user=Cookies.get('user');
		$("#username").val(cookie_user);

		$.ajax({
			type: 'post',
			url: 'https://kostours.lex.style/loginmsg',
			dataType: 'json',
			success: function(data) {
				$("#login_errors").hide();
			},
			error: function(request,status,error) {
				var errorType = request.responseText;
				if(errorType === 'Incorrect Credentials')
				{
					$("#login_messages").append("<div id='login_errors' class='alert alert-danger mt-3' role='alert'><strong>Error:</strong> Incorrect credentials.</div>");
					$("#login_errors").show();
				}
			}
		});
	}
	if(url.indexOf("/bookings-list") > -1)
	{
		$('#editdate_picker').daterangepicker({
			singleDatePicker: true,
			showDropdowns: true,
		});
		$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_table',
				dataType: 'json',
				async: false,			
				success: function(data) {
					//alert('bien');
					if(data!=null)
					{
						$("#bkng_table").empty();
						var content="";
						$.each( data, function( key, value ) {
							var date=moment(value.date).utc().format('MM/DD/YYYY');
							var date_p=moment(value.date_purchased).utc().format('MM/DD/YYYY');
							var status=0;
							var subtract = (value.price * 10 / 100);
							var refund = value.price - subtract;
							if(value.status===0) status="Pending";
							if(value.status===1) status="Accepted";
							if(value.status===2) status="Finished";
							if(value.status===3) status="Cancelled";
							if(value.status===4) status="Refunded";
							//Treat name for backslashes from database
							var reg_backslash=/\\/g;
							var clientName=value.name;
							var namec_clean=clientName.replace(reg_backslash,"");
							//END Cleaning name
							//Treat name to not show apostrophes in the html code
							var name4code =  namec_clean.replace(/'/g, "");
							//END apostrophes
							content+="<tr>";
							content+="<td>"+namec_clean+"</td>";
							content+="<td>"+value.email+"</td>";
							content+="<td>"+value.phone+"</td>";
							content+="<td>"+value.tour+"</td>";
							content+="<td>"+date+"</td>";
							content+="<td>"+value.guests+"</td>";
							content+="<td>"+date_p+"</td>";
							content+="<td>"+status+"</td>";
							content+="<td><div class='btn-group dropdown'><a href='#' class='dropdown-toggle arrow-none btn btn-light btn-sm' data-toggle='dropdown' aria-expanded='false'><i class='mdi mdi-dots-horizontal'></i></a><div class='dropdown-menu dropdown-menu-right'>";
							if(value.status===0) {
								content+="<button id='btn_accept' class='dropdown-item' data-id='"+value.stripe_customer+"' data-price='"+value.price+"' data-date='"+date+"' data-name='"+name4code+"' data-email='"+value.email+"' data-toggle='modal' data-target='#acceptModal'><i class='mdi mdi-credit-card mr-1 text-muted'></i>Accept Booking</button>";
								content+="<button id='btn_cancel' class='dropdown-item' data-id='"+value.id+"' data-name='"+name4code+"' data-email='"+value.email+"' data-toggle='modal' data-target='#cancelModal'><i class='mdi mdi-delete mr-1 text-muted'></i>Cancel Booking</button>";							
								content+="<button id='btn_edit' class='dropdown-item' data-id='"+value.id+"' data-date='"+date+"' data-name='"+name4code+"' data-email='"+value.email+"' data-toggle='modal' data-target='#editModal'><i class='mdi mdi-calendar-edit mr-1 text-muted'></i>Change Date</button>";
								content+="<button id='btn_mark' class='dropdown-item' data-id='"+value.id+"' data-name='"+name4code+"' data-email='"+value.email+"' data-toggle='modal' data-target='#markModal'><i class='mdi mdi-check-all mr-1 text-muted'></i>Mark Accepted</button>";					
								content+="<button id='btn_edit_total' class='dropdown-item' data-id='"+value.id+"' data-toggle='modal' data-target='#editTotalModal'><i class='mdi mdi-currency-usd mr-1 text-muted'></i>Change Price</button>";
								content+="<a class='dropdown-item' href='client?id="+value.id+"'><i class='mdi mdi-account mr-1 text-muted'></i>View Client</a>";
							}
							if(value.status===1) {
								content+="<button id='btn_finish' class='dropdown-item' data-id='"+value.id+"' data-phone='"+value.phone+"' data-rwid='"+value.review_id+"' data-toggle='modal' data-target='#finishModal'><i class='mdi mdi-check mr-1 text-muted'></i>Finish</button>";
								content+="<button id='btn_refund' data-id='"+value.stripe_charge+"' data-price='"+value.price+"' data-refund='"+refund.toFixed(2)+"' data-toggle='modal' data-target='#refundModal' class='dropdown-item' href='#'><i class='mdi mdi-credit-card-off mr-1 text-muted'></i>Refund</button>";							
								content+="<button id='btn_edit' class='dropdown-item' data-id='"+value.id+"' data-date='"+date+"' data-name='"+name4code+"' data-email='"+value.email+"' data-toggle='modal' data-target='#editModal'><i class='mdi mdi-calendar-edit mr-1 text-muted'></i>Change Date</button>";							
								content+="<a class='dropdown-item' href='client?id="+value.id+"'><i class='mdi mdi-account mr-1 text-muted'></i>View Client</a>";
							}
							if(value.status===2 || value.status===4) {							
								content+="<a class='dropdown-item' href='client?id="+value.id+"'><i class='mdi mdi-account mr-1 text-muted'></i>View Client</a>";
							}
							if(value.status===3) {
								content+="<button class='dropdown-item' data-id='"+value.id+"' data-toggle='modal' data-target='#restoreModal'><i class='mdi mdi-restore mr-1 text-muted'></i>Recover</button>";
								content+="<a class='dropdown-item' href='client?id="+value.id+"'><i class='mdi mdi-account mr-1 text-muted'></i>View Client</a>";
							}
							content+="</div></div></td></tr>";
						});
						$("#bkng_table").html(content);
					}
				},
				error : function(request, status, error) {

					var val = request.responseText;
					alert("error"+status);
				}
		});
		 /*Refund Modal========================================*/
		$("#refund_percent").change(function(){
			var percent = $('#refund_percent').val();
			
			if(!$.isNumeric(percent))
			{
				swal({ title: 'Error', text: "Please introduce a numeric value",type: 'error'});
			}
			else {
				const formatter = new Intl.NumberFormat('en-US', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				//var total = $('#charge_total').val();
				//var subtract = (total * percent / 100);
				//var toRefund = total - subtract;
				$("#refund_total").text("$" + formatter.format(percent));
			}
		});
		$('#refund_submit').click(function () {	
			//Turn into spinner disabled
			$('#refund_submit').prop('disabled', true);
			$('#refund_close').prop('disabled', true);
			$('#refund_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			//Delete dollar symbol
			var percent = $('#refund_percent').val();
			var total = $('#charge_total').val();
			
			if(!$.isNumeric(percent))
			{
				swal({ title: 'Error', text: "Please introduce a numeric value",type: 'error'});
			}
			else {
				const formatter = new Intl.NumberFormat('en-US', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				//var subtract = (total * percent / 100);
				var toRefund = formatter.format(percent);
				//alert('The amount to subtract is '+subtract);
				//alert('The amount to refund is '+toRefund);
				var data = {};
				data.cid = $('#charge_id').val();
				data.refund = toRefund;
				$.ajax({
					type: 'post',
					url: 'https://kostours.lex.style/bkng_refund',
					dataType: 'json',
					data: data,		
					success: function(data) {
						//alert(data);
						$('#refundModal').modal('hide');
						swal(
							{
								title: 'Success',
								text: 'The charge has been refunded correctly!',
								type: 'success',
								confirmButtonClass: 'btn btn-confirm mt-2'
							}
						).then(function (result) {
							if (result.value) {
							// handle confirm
								location.reload();
							}
						})					
					},
					error : handleErrors
				});
			}			
		});
		//triggered when modal is hidden
		$('#refundModal').on('hidden.bs.modal', function(e) {
			$('#refund_total').empty();
			$('#refund_percent').val(0);
		});
		//triggered when modal is about to be shown
		$('#refundModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var chargeID = $(e.relatedTarget).data('id');
			var chargeTotal=$(e.relatedTarget).data('price');
			var refund=$(e.relatedTarget).data('price');
			//populate the fields
			$(e.currentTarget).find('input[name="charge_id"]').val(chargeID);
			$(e.currentTarget).find('#charge_total').val(chargeTotal);		
			$(e.currentTarget).find('#refund_total').text('$'+refund);
			$(e.currentTarget).find('#refund_percent').val(chargeTotal);
			
		});
		/*END Refund modal====================================*/
		 /*Change Total Price Modal========================================*/
		$("#editTotal_price").change(function(){
			var value = $('#editTotal_price').val();
			if(!$.isNumeric(value))
			{
				swal({ title: 'Error', text: "Please introduce a numeric value",type: 'error'});
			}
		});
		$('#editTotal_submit').click(function () {	
			//Turn into spinner disabled
			$('#editTotal_submit').prop('disabled', true);
			$('#editTotal_close').prop('disabled', true);
			$('#editTotal_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			var value = $('#editTotal_price').val();
			if(!$.isNumeric(value))
			{
				swal({ title: 'Error', text: "Please introduce a numeric value",type: 'error'});
			}
			else {
				var data = {};
				data.id = $('#editTotal_id').val();
				data.total = value;
				$.ajax({
					type: 'post',
					url: 'https://kostours.lex.style/bkng_edit_total',
					dataType: 'json',
					data: data,		
					success: function(data) {
						//alert(data);
						$('#editTotalModal').modal('hide');
						swal(
							{
								title: 'Success',
								text: 'The total price has been modified correctly!',
								type: 'success',
								confirmButtonClass: 'btn btn-confirm mt-2'
							}
						).then(function (result) {
							if (result.value) {
							// handle confirm
								location.reload();
							}
						})					
					},
					error : handleErrors
				});
			}			
		});
		//triggered when modal is hidden
		$('#editTotalModal').on('hidden.bs.modal', function(e) {
			$('#editTotal_price').empty();
		});
		//triggered when modal is about to be shown
		$('#editTotalModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var editTotalID = $(e.relatedTarget).data('id');
			//populate the fields
			$(e.currentTarget).find('input[name="editTotal_id"]').val(editTotalID);			
		});
		/*END Change Total Price Modal====================================*/
		/*Edit Modal========================================*/
		$('#edit_submit').click(function () {	
		//Turn into spinner disabled
			$('#edit_submit').prop('disabled', true);
			$('#edit_cancel').prop('disabled', true);
			$('#edit_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			var data = {};
			data.id = $('#edit_id').val();
			data.date = $('#editdate_picker').val();
			data.name=$('#edit_name').val();
			data.email=$('#edit_email').val();
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_edit',
				dataType: 'json',
				data: data,			
				success: function(data) {
					$('#editModal').modal('hide');
					swal(
						{
							title: 'Success!',
							text: 'The reservation date has been edited correctly!',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : handleErrors
			});			
		});
		//triggered when modal is about to be shown
		$('#editModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var cID = $(e.relatedTarget).data('id');
			var editDate = $(e.relatedTarget).data('date');
			var editName = $(e.relatedTarget).data('name');
			var editEmail = $(e.relatedTarget).data('email');
			//populate the textbox
			$(e.currentTarget).find('input[name="edit_id"]').val(cID);
			$(e.currentTarget).find('input[name="edit_name"]').val(editName);
			$(e.currentTarget).find('input[name="edit_email"]').val(editEmail);
			$(e.currentTarget).find('input[name="editdate_picker"]').val(editDate);
		});
		/*END Edit modal====================================*/
		 /*Finish Modal========================================*/
		$('#finish_submit').click(function () {	
		//Turn into spinner disabled
			$('#finish_submit').prop('disabled', true);
			$('#finish_cancel').prop('disabled', true);
			$('#finish_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			var data = {};
			data.value = $('#customer_id').val();
			data.phone = $('#phone_number').val();
			data.rwid = $('#rwid').val();
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_finish',
				dataType: 'json',
				data: data,			
				success: function(data) {
					$('#finishModal').modal('hide');
					swal(
						{
							title: 'Success!',
							text: 'The reservation has been finished correctly! A text message has been sent to review the experience.',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : handleErrors
			});			
		});
		//triggered when modal is about to be shown
		$('#finishModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var cID = $(e.relatedTarget).data('id');
			var phone = $(e.relatedTarget).data('phone');
			var rwId = $(e.relatedTarget).data('rwid');
			//populate the textbox
			$(e.currentTarget).find('input[name="customer_id"]').val(cID);
			$(e.currentTarget).find('input[name="phone_number"]').val(phone);
			$(e.currentTarget).find('input[name="rwid"]').val(rwId);
		});
		/*END Finish modal====================================*/
		/*Cancel Modal========================================*/
		$('#cancel_submit').click(function () {	
		//Turn into spinner disabled
			$('#cancel_submit').prop('disabled', true);
			$('#cancel_close').prop('disabled', true);
			$('#cancel_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			var data = {};
			data.value = $('#cancel_id').val();
			data.name = $('#cancel_name').val();
			data.email = $('#cancel_email').val();
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_cancel',
				dataType: 'json',
				data: data,
				success: function(data) {
					$('#cancelModal').modal('hide');
					swal(
						{
							title: 'Success',
							text: 'The reservation has been cancelled correctly!',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : handleErrors
			});			
		});
		//triggered when modal is about to be shown
		$('#cancelModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var cancelID = $(e.relatedTarget).data('id');
			var caName = $(e.relatedTarget).data('name');
			var caMail = $(e.relatedTarget).data('email');
			//populate the textbox
			$(e.currentTarget).find('input[name="cancel_id"]').val(cancelID);
			$(e.currentTarget).find('input[name="cancel_name"]').val(caName);
			$(e.currentTarget).find('input[name="cancel_email"]').val(caMail);
		});
		/*END Cancel modal====================================*/
		/*Restore Modal========================================*/
		$('#restore_submit').click(function () {	
			$('#restore_submit').prop('disabled', true);
			$('#restore_close').prop('disabled', true);
			$('#restore_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			var data = {};
			data.value = $('#restore_id').val();
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_restore',
				dataType: 'json',
				data: data,
				success: function(data) {
					$('#restoreModal').modal('hide');
					swal(
						{
							title: 'Success',
							text: 'The reservation has been restored correctly!',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : handleErrors
			});			
		});
		//triggered when modal is about to be shown
		$('#restoreModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var restoreID = $(e.relatedTarget).data('id');
			//populate the textbox
			$(e.currentTarget).find('input[name="restore_id"]').val(restoreID);
		});
		/*END Restore modal====================================*/
		/*Accept Modal========================================*/
		$('#accept_submit').click(function () {
			//Turn into spinner disabled
			$('#accept_submit').prop('disabled', true);
			$('#acc_cancel').prop('disabled', true);
			$('#accept_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
			//$('#accept_submit').button('loading');
			//Delete dollar symbol
			var total = $('#accept_amount').text();
			var clean_total = total.substring(1, total.length);
			var data = {};
			data.cid = $('#accept_id').val();
			data.total = clean_total;
			data.email = $('#accept_email').text();
			data.name = $('#accept_name').text();
			data.date = $('#accept_date').val();
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_accept',
				dataType: 'json',
				data: data,			
				success: function(data) {
					//alert(data);
					$('#acceptModal').modal('hide');
					swal(
						{
							title: 'Success',
							text: 'The reservation has been accepted and charged correctly!',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : handleErrors
			});	
		});
		//triggered when modal is about to be shown
		$('#acceptModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var acceptID = $(e.relatedTarget).data('id');
			var acceptName=$(e.relatedTarget).data('name');
			var acceptEmail=$(e.relatedTarget).data('email');
			var acceptTotal=$(e.relatedTarget).data('price');
			var acceptDate=$(e.relatedTarget).data('date');
			//populate the fields
			$(e.currentTarget).find('input[name="accept_id"]').val(acceptID);
			$(e.currentTarget).find('span[id="accept_name"]').text(acceptName);
			$(e.currentTarget).find('#accept_email').text(acceptEmail);
			$(e.currentTarget).find('#accept_amount').text('$'+acceptTotal);
			$(e.currentTarget).find('#accept_date').val(acceptDate);
		});
		/*END Accept modal====================================*/
		/*Mark Modal========================================*/
		$('#mark_submit').click(function () {
			//Turn into spinner disabled
			$('#mark_submit').prop('disabled', true);
			$('#mark_cancel').prop('disabled', true);
			$('#mark_submit').html("<span class='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>&nbsp;Loading...");
						
			var data = {};
			data.cid = $('#mark_id').val();
			/*data.email = $('#mark_email').text();
			data.name = $('#mark_name').text();*/
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/bkng_mark',
				dataType: 'json',
				data: data,			
				success: function(data) {
					//alert(data);
					$('#markModal').modal('hide');
					swal(
						{
							title: 'Success',
							text: 'The reservation has been marked as accepted correctly!',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : handleErrors
			});	
		});
		//triggered when modal is about to be shown
		$('#markModal').on('show.bs.modal', function(e) {
			//get data-id attribute of the clicked element
			var markID = $(e.relatedTarget).data('id');
			var markName=$(e.relatedTarget).data('name');
			var markEmail=$(e.relatedTarget).data('email');
			//populate the fields
			$(e.currentTarget).find('input[name="mark_id"]').val(markID);
			$(e.currentTarget).find('span[id="mark_name"]').text(markName);
			$(e.currentTarget).find('#mark_email').text(markEmail);
		});
		/*END Mark modal====================================*/
	}
	if(url.indexOf("/client") > -1)
	{
//	if(window.location.href.indexOf("https://kostours.lex.style:1000:7000/client.html") > -1)
//	{
		var data = {};
		data.value = getUrlParameter('id');
		$.ajax({
			type: 'post',
			url: 'https://kostours.lex.style/client',
			dataType: 'json',
			data: data,
			success: function(data) {
				if(data!=null)
				{
					$("#client_info").empty();
					var content="";
					$.each( data, function( key, value ) {
						var date=moment(value.date).utc().format('MM/DD/YYYY');
						var date_p=moment(value.date_purchased).utc().format('MM/DD/YYYY');
						var badge="";
						if(value.status===0) badge="<span class='badge badge-primary'>Pending</span>";
						if(value.status===1) badge="<span class='badge badge-success'>Accepted</span>";
						if(value.status===2) badge="<span class='badge badge-secondary'>Finished</span>";
						if(value.status===3) badge="<span class='badge badge-dark'>Cancelled</span>";
						if(value.status===4) badge="<span class='badge badge-light'>Refunded</span>";
						//Clean variables from backslashes from DB
						var reg_backslash=/\\/g;
						var pickup=value.address;
						var pickup_clean=pickup.replace(reg_backslash,"");
						var client_name=value.name;
						var name_clean=client_name.replace(reg_backslash,"");
						content+="<ul class='list-group list-group-flush'>";
						content+="<li class='list-group-item'><p class='h3'>"+name_clean+"<span style='margin-left:10px;'>"+badge+"</span></p></li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Email:</span> "+value.email+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Phone:</span> "+value.phone+"</li>";
						content+="</ul>";
						content+="<ul class='list-group list-group-flush'>";
						content+="<li class='list-group-item'><p class='h4'>Tour Information</p></li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Tour:</span> "+value.tour+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Tour Date:</span> "+date+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Guests:</span> "+value.guests+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Pick-up Address:</span> "+pickup_clean+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Date reserved:</span> "+date_p+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Price:</span> $"+value.price+"</li>";
						if(value.interest)
						{
							//Clean variables from backslashes from DB
							var interest=value.interest;
							var interest_clean=interest.replace(reg_backslash,"");
							content+="<li class='list-group-item'><span class='font-weight-bold'>Interests:</span> "+interest_clean+"</li>";
						}
						if(value.notes)
						{
							//Clean variables from backslashes from DB
							var notes=value.notes;
							var notes_clean=notes.replace(reg_backslash,"");
							content+="<li class='list-group-item'><span class='font-weight-bold'>Notes:</span> "+notes_clean+"</li>";
						}
						content+="</ul>";
						content+="<ul class='list-group list-group-flush'>";
						content+="<li class='list-group-item'><p class='h4'>Billing Information</p></li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Name on Card:</span> "+value.card_name+"</li>";
						content+="<li class='list-group-item'><span class='font-weight-bold'>Stripe Customer:</span> "+value.stripe_customer+"</li>";
						if(value.stripe_charge)
						{
							content+="<li class='list-group-item'><span class='font-weight-bold'>Stripe Charge:</span> "+value.stripe_charge+"</li>";
						}
						if(value.stripe_refund)
						{
							content+="<li class='list-group-item'><span class='font-weight-bold'>Stripe Refund:</span> "+value.stripe_refund+"</li>";
						}
						
						content+="</ul>";
					});
					$("#client_info").html(content);
				}
			},
			error : function(request, status, error) {

				var val = request.responseText;
				alert("error"+status);
			}
		});
	}
	if(url.indexOf("/bookings-conf") > -1)
	{
//	if(url==="https://kostours.lex.style:1000:7000/bookings-conf.html")
//	{
		//Load select box
		$.ajax({
			type: 'post',
			url: 'https://kostours.lex.style/tours',
			dataType: 'json',		
			success: function(data) {
				$.each( data, function( key, value ) {
					$('#close_select').append($('<option>', { 
						value: value.id,
						text : value.name 
					}));				
				});
				$('#close_select').niceSelect('update');
			},
			error : function(request, status, error) {
				var val = request.responseText;
				alert("error"+status);
			}
		});
		//Load closed days
		var today=moment.utc().format('YYYY-MM-DD');
		var dt = {};
		dt.today = today;
		$.ajax({
			type: 'post',
			url: 'https://kostours.lex.style/closed_days',
			dataType: 'json',
			data: dt,
			async: false,			
			success: function(data) {
				$("#closed-list").empty();
				var content="";
				$.each( data, function( key, value ) {
					content+="<button class='list-group-item list-group-item-action' id='"+value.id_conf+"' data-toggle='list' role='tab' aria-controls='home'>"+moment.utc(value.day).format('MM/DD/YYYY')+" - "+value.name+"</button>";					
				});
				$("#closed-list").html(content);
			},
			error : function(request, status, error) {
				var val = request.responseText;
				alert("error"+status);
			}
		});
		$('#bkngconf_picker').daterangepicker({
			singleDatePicker: true,
			showDropdowns: true,
		});
		//Open Days
		$('#open_submit').click(function () {
			var items = $(".list-group-item.active").prop('id');
			var data = {};
			data.open = items;
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/open_tour',
				dataType: 'json',
				data: data,
				async: false,			
				success: function(data) {
					swal(
						{
							title: 'Success',
							text: 'The day has been opened successfully',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : function(request, status, error) {
					swal({ title: 'Error', text: "Something went wrong opening the day",type: 'error'});
				}
			});			
		});
		$('#close_submit').click(function () {	
			var date=moment($("#bkngconf_picker").val()).utc().format('YYYY-MM-DD');
			var tour=$("#close_select").val();
			var data = {};
			data.date = date;
			data.tour = tour;
			$.ajax({
				type: 'post',
				url: 'https://kostours.lex.style/close_tour',
				dataType: 'json',
				data: data,
				async: false,			
				success: function(data) {
					swal(
						{
							title: 'Success',
							text: 'The day has been closed successfully',
							type: 'success',
							confirmButtonClass: 'btn btn-confirm mt-2'
						}
					).then(function (result) {
						if (result.value) {
						// handle confirm
							location.reload();
						}
					})					
				},
				error : function(request, status, error) {
					swal({ title: 'Error', text: "Something went wrong closing the day",type: 'error'});
				}
			});			
		});
	}
	/*=========================================
	====== Password Change PAGE =========================
	*/
	//if(url.indexOf("kostours.lex.style:1000") > -1)
	//{
	if(url.indexOf("/password-change") > -1)
	{
		$("#passwd-form").submit(function( event ) {
			event.preventDefault();
			var npass=$("#new_password").val();
			var npass2=$("#new_password2").val();
			var data = {};
			data.password = npass;
			data.password2 = npass2;
			if(npass===npass2 && npass!="" && npass2!="")
			{
				$.ajax({
					type: 'post',
					url: 'https://kostours.lex.style/change-pass',
					dataType: 'json',
					data: data,			
					success: function(data) {
						swal(
							{
								title: 'Success',
								text: 'The password has been successfully changed',
								type: 'success',
								confirmButtonClass: 'btn btn-confirm mt-2'
							}
						).then(function (result) {
							if (result.value) {
							// handle confirm
								location.reload();
							}
						})					
					},
					error : function(request, status, error) {
						swal({ title: 'Error', text: "The passwords do not match",type: 'error'});
					}
				});		
			}
			else {
				swal({ title: 'Error', text: "The passwords do not match",type: 'error'});
			}
		});
	}
	if(url.indexOf("/reputation") > -1)
	{
		$.ajax({
			type: 'get',
			url: 'https://kostours.lex.style/reviews',
			dataType: 'json',	
			success: function(data) {
				var yelpdif=(data[0].YelpFinal-data[0].YelpInitial);
				var tripdif=(data[0].TripFinal-data[0].TripInitial);
				var googledif=(data[0].GoogleFinal-data[0].GoogleInitial);
				if(yelpdif!==0) yelpdif='+'+yelpdif;
				else yelpdif="";
				if(tripdif!==0) tripdif='+'+tripdif;
				else tripdif="";
				if(googledif!==0) googledif='+'+googledif;
				else googledif="";
				
				$('#googlereviews').html(data[0].GoogleFinal+' <span class="badge badge-success">'+googledif+'</span>');
				$('#tripreviews').html(data[0].TripFinal+' <span class="badge badge-success">'+tripdif+'</span>');
				$('#yelpreviews').html(data[0].YelpFinal+' <span class="badge badge-success">'+yelpdif+'</span>');
			}
		});	
		//Fill Clients that left a review table
		$.ajax({
				type: 'get',
				url: 'https://kostours.lex.style/leftreview',
				dataType: 'json',		
				success: function(data) {
					//alert('bien');
					if(data!=null)
					{
						$("#dash_bkng").empty();
						var content="";
						$.each( data, function( key, value ) {
							var date=moment(value.date).utc().format('MM/DD/YYYY');
							var date_review=moment(value.review_date).utc().format('MM/DD/YYYY');
							content+="<tr>";
							content+="<td><p class='mb-0 font-weight-bold'><a href='https://kostours.lex.style/client?id="+value.id+"'>"+value.name+"</a></p><span class='font-13'>"+value.email+"</span></td>";
							content+="<td><span class='phone'>"+value.phone+"</span></td>";
							content+="<td><span class='tour_name'>"+value.tour+"</span></td>";
							content+="<td><span class='tour_date'>"+date+"</span></td>";
							content+="<td><span class='guests'>"+value.guests+"</span></td>";
							content+="<td><span class='reviewed'>"+date_review+"</span></td>";
							content+="</tr>";
						});
						$("#dash_bkng").html(content);
					}
				},
				error : function(request, status, error) {

					var val = request.responseText;
					alert("error"+status);
				}
		});
	}
});
//END My oqn jQuery ---- ALEX